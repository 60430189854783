<template>
  <div>
    <div class="main-content main-content-about">
      <div class="container">
        <div class="row">
          <div class="col-lg-12">
            <div class="breadcrumb-trail breadcrumbs">
              <ul class="trail-items breadcrumb">
                <li class="trail-item trail-begin">
                  <router-link :to="{ name: 'Home' }"> Principal </router-link>
                </li>
                <li class="trail-item trail-end active">
                  Acerca de Nosotros
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row">
          <div
            class="content-area content-about col-lg-12 col-md-12 col-sm-12 col-xs-12"
          >
            <div class="site-main">
              <h3 class="custom_blog_title">Sobre nosotros</h3>
              <div class="page-main-content">
                <div class="about-img-container">
                  <img
                    class="about-img"
                    :src="imgSrcs.aboutUs"
                    alt="Image"
                    style="max-height: 300px;"
                  />
                </div>
                <div class="about-text-container">
                  <div class="about-text">
                      <p class="pre-formatted">
                        {{textAboutUs}}
                      </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters({
          imgSrcs: "ecommerce/imgSrcs",
          configuration: "ecommerce/configuration",
        }),
        textAboutUs() {
            return this.configuration.fillers.aboutus;
        },
    },
};
</script>

<style scoped>

.pre-formatted {
  white-space: pre-line;
}

.row-about {
  max-width: 1100px;
}

.about-text-container {
  margin-top: 4em;
}

.about-text {
  font-size: 1em;
}

.about-img {
  object-fit: cover;
  width: 100%;
  height: 300px;
}

@media screen and (max-width: 768px) {
  .about-img {
    height: 200px;
  }
}

@media screen and (max-width: 500px) {
  .about-img {
    height: 150px;
  }
}
</style>
